<template>
  <div class="d-flex kt-wizard-v4" id="kt_wizard_v4" data-ktwizard-state="step-first">
    <!--begin: Form Wizard Nav -->
    <div class="kt-wizard-v4__nav">

      <div class="kt-wizard-v4__nav-items kt-wizard-v4__nav-items--clickable">
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              1
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.ADMIN_FORM.FORM.STEP.ONE.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.ADMIN_FORM.FORM.STEP.ONE.DESC") }}
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              2
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.ADMIN_FORM.FORM.STEP.TWO.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.ADMIN_FORM.FORM.STEP.TWO.DESC") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end: Form Wizard Nav -->
    <portlet body-fit class="border-start-0" body-class="border-start-0" >
      <template v-slot:body>
        <div class="kt-grid">
          <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">

            <!--begin: Form Wizard Form-->

            <div class="kt-form" id="kt_form">
              <!--begin: Form Wizard Step 1-->
              <div class="kt-wizard-v4__content" data-ktwizard-type="step-content">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ADMIN_FORM.FORM.STEP.ONE.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-name" :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.name')" label-for="name">
                          <b-form-input id="name" class="direction-ltr" v-model="formData.name" :state="validateState('name')" aria-describedby="name-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.PLACEHOLDER.name')" />
                          <b-form-invalid-feedback id="name-live-feedback">
                            {{ $t('COMPONENTS.FORMS.ADMIN_FORM.FORM.FEEDBACK.name') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-nickname" :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.nickname')" label-for="nickname">
                          <b-form-input id="nickname" v-model="formData.nickname" :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.PLACEHOLDER.nickname')" />
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-phone" :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.phone')" label-for="phone">
                          <b-form-input id="phone" type="tel" class="direction-ltr" v-model="formData.phone" :state="validateState('phone')" aria-describedby="phone-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.PLACEHOLDER.phone')" />
                          <b-form-invalid-feedback id="phone-live-feedback">
                            {{ $t('COMPONENTS.FORMS.ADMIN_FORM.FORM.FEEDBACK.phone') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="action === 'create'">
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-password" :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.password')" label-for="password">
                          <b-input-group class="direction-ltr">
                            <b-input-group-prepend>
                              <b-button @click="onClickGeneratePassword">{{ $t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.generate') }}</b-button>
                            </b-input-group-prepend>
                            <b-form-input id="password" trim v-model="formData.password" :state="validateState('password')" aria-describedby="password-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.PLACEHOLDER.password')" />
                          </b-input-group>
                          <b-form-invalid-feedback id="password-live-feedback">
                            {{ $t('COMPONENTS.FORMS.ADMIN_FORM.FORM.FEEDBACK.password') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-password_confirmation" :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.password_confirmation')" label-for="password_confirmation">
                          <b-form-input id="password_confirmation" class="direction-ltr" v-model="formData.password_confirmation" :state="validateState('password_confirmation')" aria-describedby="password_confirmation-live-feedback" :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.PLACEHOLDER.password_confirmation')" />
                          <b-form-invalid-feedback id="password_confirmation-live-feedback">
                            {{ $t('COMPONENTS.FORMS.ADMIN_FORM.FORM.FEEDBACK.password_confirmation') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-super" :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.super')" label-for="super">
                          <b-form-radio-group
                            id="super"
                            v-model="formData.super"
                            :options="yesOrNot"
                            button-variant="warning"
                            buttons />
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-sub_account" :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.sub_account')" label-for="sub_account">
                          <b-form-radio-group
                            id="sub_account"
                            v-model="formData.sub_account"
                            :options="yesOrNot"
                            button-variant="warning"
                            @change="onChangeSubAccount"
                            buttons />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 1-->

              <!--begin: Form Wizard Step 2-->
              <div class="kt-wizard-v4__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.ADMIN_FORM.FORM.STEP.TWO.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-col sm="6" md="12">
                      <b-form-group
                        id="input-group-categories"
                        :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.regulators')"
                        label-for="regulators"
                      >
                        <multi-select id="regulators"
                            :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.multiSelector.placeholder')"
                            :select-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.multiSelector.selectLabel')"
                            :selected-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.multiSelector.selectedLabel')"
                            :deselect-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.multiSelector.deselectLabel')"
                            tag-position="bottom"
                            :searchable="false"
                            :label="`${lang}-title`"
                            :track-by="`${lang}-title`"
                            :multiple="!formData.sub_account"
                            v-model="formData.regulators"
                            :options="regulatorOptions" />
                      </b-form-group>
                    </b-col>
                    <b-col v-if="formData.sub_account" sm="6" md="12">
                      <b-form-group
                        id="input-group-actuators"
                        :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.actuators')"
                        label-for="actuators"
                      >
                        <multi-select id="actuators"
                          :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.singleSelector.placeholder')"
                          :select-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.singleSelector.selectLabel')"
                          :selected-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.singleSelector.selectedLabel')"
                          :deselect-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.singleSelector.deselectLabel')"
                          tag-position="bottom"
                          :searchable="false"
                          :label="`${lang}-title`"
                          :track-by="`${lang}-title`"
                          :multiple="false"
                          v-model="formData.actuators"
                          :options="actuatorOptions" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group
                        id="input-group-merchants"
                        :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.merchants')"
                        label-for="merchants"
                      >
                        <multi-select id="merchants"
                          :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.merchantSelector.placeholder')"
                          :select-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.merchantSelector.selectLabel')"
                          :selected-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.merchantSelector.selectedLabel')"
                          :deselect-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.merchantSelector.deselectLabel')"
                          tag-position="bottom"
                          :searchable="false"
                          :label="`${lang}-title`"
                          :track-by="`${lang}-title`"
                          :multiple="true"
                          v-model="formData.merchants"
                          :options="merchantOptions" />
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" md="12">
                      <b-form-group
                              id="input-group-project_leaders"
                              :label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.project_leaders')"
                              label-for="project_leaders"
                      >
                        <multi-select id="project_leaders"
                            :placeholder="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.projectLeaderSelector.placeholder')"
                            :select-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.projectLeaderSelector.selectLabel')"
                            :selected-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.projectLeaderSelector.selectedLabel')"
                            :deselect-label="$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.LABEL.projectLeaderSelector.deselectLabel')"
                            tag-position="bottom"
                            :searchable="false"
                            :label="`${lang}-title`"
                            :track-by="`${lang}-title`"
                            :multiple="true"
                            v-model="formData.project_leaders"
                            :options="projectLeaderOptions" />
                      </b-form-group>
                    </b-col>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 2-->

              <!--begin: Form Actions -->
              <div class="kt-form__actions">
                <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                  {{ $t("COMMON.FORM.BUTTONS.PREV") }}
                </button>
                <button class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit" @click="onSubmit">
                  {{ $t("COMMON.FORM.BUTTONS.SUBMIT") }}
                </button>
                <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                  {{ $t("COMMON.FORM.BUTTONS.NEXT") }}
                </button>
              </div>
              <!--end: Form Actions -->
            </div>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </template>
    </portlet>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import KTWizard from "@/assets/js/wizard";
  import KTUtil from "@/assets/js/util";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required, sameAs } from "vuelidate/lib/validators";
  import { randomString } from "@c@/helpers/helpers";
  import "vue-multiselect/dist/vue-multiselect.min.css";
  import MultiSelect from 'vue-multiselect';
  import adminMixin from "@m@/admin.mixin";
  import articleMixin from "@m@/article.mixin";
  import merchantMixin from "@m@/merchant.mixin";
  import {mapGetters} from "vuex";
  export default {
    name: "AdminForm",
    props: {
      action: {
        type: String,
        default: 'create'
      },
      id: {
        type: [String, Number],
      },
    },
    emits: ['loading', 'loaded'],
    components: {Portlet, MultiSelect},
    computed: {
      ...mapGetters(['articleCoreData', 'currentAdmin']),
      lang(){
        const { locale } = this.$i18n;
        return locale
      },
      regulatorOptions(){
        let { regulators = [] } = this.articleCoreData || {};
        return regulators;
      },
      actuatorOptions(){
        let { actuators = [] } = this.articleCoreData || {};
        return actuators;
      },
      merchantOptions(){
        let { merchants = [] } = this.articleCoreData || {};
        return merchants;
      },
      projectLeaderOptions(){
        let { project_leaders = [] } = this.articleCoreData || {};
        return project_leaders;
      },
    },
    data() {
      return {
        loading: false,
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        formData: {
          name: null,
          nickname: null,
          password: null,
          password_confirmation: null,
          phone: null,
          regulators: null,
          actuators: null,
          merchants: [],
          project_leaders: [],
          super: false,
          sub_account: false,
        }
      }
    },
    mixins: [adminMixin, sweetAlertMixin, validateStateMixin, validationMixin, articleMixin, merchantMixin],
    validations(){
      let validates = {
        name: { required },
        phone: { required },
      };
      if( this.action === 'create' ){
        validates.password = { required };
        validates.password_confirmation = { sameAsPassword: sameAs('password') };
      }
      return {
        formData: validates
      }
    },
    created() {
      this.getArticleCoreData();
      if (this.action === 'edit'){
        this.getAdmin(this.id)
          .then(res=>{
            this.formData = Object.assign({}, res.data)
          })
      }
    },
    mounted() {
      this.wizardHandler();
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.TIPS.VALIDATION_TIPS')});
        }

        if (this.action === 'create'){
          this.storeAdmin(this.formData)
            .then(()=> {
              this.$router.replace({ name: `admin.home` });
            }).catch(err=>{
              console.info('storeAdmin.err: ', err)
            })
        }else if ( this.action === 'edit' ){
          this.updateAdmin(this.id, this.formData)
            .then(()=>{
              this.success({ message: this.$t("COMPONENTS.FORMS.ADMIN_FORM.FORM.TIPS.EDIT_SUCCESS") })
            })
            .catch(err=>{
              console.info('updateAdmin.err: ', err)
            })
        }
      },
      wizardHandler(){
        // Initialize form wizard
        this.wizard = new KTWizard('kt_wizard_v4', {
          startStep: 1, // initial active step number
          clickableSteps: true  // allow step clicking
        });
        this.$nextTick(function () {
          const self = this;
          // Validation before going to next page
          this.wizard.on('beforeNext', function(wizardObj) {
            // console.info('beforeNext.wizardObj: ', wizardObj);
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          this.wizard.on('beforePrev', function(wizardObj) {
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          // Change event
          this.wizard.on('change', function(wizard) {
            KTUtil.scrollTop();
          });
        });
      },
      validHandler(formName = 'name', wizardObj){
        if( formName ){
          this.$v.formData[formName].$touch();
        }else {
          this.$v.formData.$touch();
        }
        // if (this.$v.formData[formName].$anyError === true) {
        if (this.$v.formData.$anyError === true) {
          wizardObj.stop();
          this.warning({message: this.$t('COMPONENTS.FORMS.ADMIN_FORM.FORM.TIPS.VALIDATION_TIPS')});
          return false;
        }else {
          return true;
        }
      },
      stepValidHandler(currentStep, wizardObj){
        switch (currentStep) {
          case 1:
            this.validHandler(null, wizardObj);
            break;
        }
      },
      onClickGeneratePassword(){
        const str = randomString(8);
        this.formData.password = str;
        this.formData.password_confirmation = str;
      },
      onChangeSubAccount(e){
        /*if( e ){
          this.formData.regulators = null;
        }*/
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-4";
</style>
