import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_ADMINS, GET_ADMIN, STORE_ADMIN, UPDATE_ADMIN } from "@/store/modules/admin.module";
export  default {
  methods: {
    storeAdmin(formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_ADMIN, formData), actions)
    },
    getAdmin(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ADMIN, id), actions)
    },
    updateAdmin(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_ADMIN, { id, formData }), actions)
    },
    getAdmins(page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ADMINS, page), actions)
    },
  }
}
