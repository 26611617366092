<template>
  <b-overlay :show="loading">
    <div class="home-container">
      <pagination :implementSearch="false" :meta="adminsMeta" @refresh="onRefresh" @change-page="onChangePage">
        <b-button slot="action" :to="{name: `admin.store`}" class="btn btn-label-success btn-sm border-0">
          {{ $t("COMMON.CREATE") }}
        </b-button>
      </pagination>
      <b-row>
        <b-col sm="6" md="3" v-for="item in admins" :key="item.id" class="d-flex">
          <portlet foot-class="mt-auto" title=" " head-no-border fluid-height body-class="pt-0">
            <template v-slot:title>
              <b-avatar-group v-if="!item.super" class="kt-media-group">
                <b-avatar v-b-tooltip :title="getRegulatorTitle(regulator, item)" class="kt-media" text="!" :src="regulator.logo_image_preview" v-for="regulator in item.regulators" :key="regulator.id"></b-avatar>
              </b-avatar-group>
              <b-avatar-group v-if="item.sub_account" class="kt-media-group">
                <b-avatar v-b-tooltip :title="getActuatorTitle(actuator, item)" class="kt-media" text="!" :src="actuator.logo_image_preview" v-for="actuator in item.actuators" :key="actuator.id"></b-avatar>
              </b-avatar-group>
              <template v-if="item.super">
                {{ $t("PAGES.ADMIN.HOME.LABEL.superTitle") }}
              </template>
            </template>
            <template v-slot:toolbar>
              <b-dropdown no-caret size="sm" variant="label-success" toggle-class="btn-upper btn-icon btn-icon-md btn-pill">
                <template v-slot:button-content>
                  <i class="flaticon-more-1"></i>
                </template>
                <b-dropdown-item :to="{name: 'admin.edit', params: {id: item.id}}"><i class="flaticon-list" style="padding-inline-end: 3px"></i><span class="dropdown-action-title">{{ $t('COMPONENTS.ITEMS.ARTICLE.VERSION_ITEM.ACTIONS.EDIT') }}</span></b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:body>
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact cursor-default">
                        <span class="kt-widget__label">{{ $t("PAGES.ADMIN.HOME.LABEL.title") }}</span>
                        <span class="kt-widget__data">{{ item.nickname || $t('COMMON.NO_BODY') }}</span>
                      </div>
                      <div class="kt-widget__contact cursor-default">
                        <span class="kt-widget__label">{{ $t("COMMON.PHONE") }}</span>
                        <span class="kt-widget__data">{{ item.phone || '...' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:foot>

            </template>
          </portlet>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import pagination from "@v@/components/paginate";
  import adminMixin from "@m@/admin.mixin";
  import {mapGetters} from 'vuex';
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  export default {
    name: "index",
    components: { Error, Portlet, pagination },
    mixins: [adminMixin, msgBoxConfirmMixin, commonMethodMixin],
    props: {},
    created() {
      this.autoLoadHomeData(1);
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ADMIN.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE"), route: {name: 'admin.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ADMIN.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE")});
    },
    watch: {

    },
    computed: {
      ...mapGetters([
         'admins', 'adminsMeta', 'adminsPage'
      ]),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
    },
    data(){
      return {
        loading: false,
        page: 1
      }
    },
    methods: {
      autoLoadHomeData(rootPage = null){
        this.loading = true;
        let {adminsPage} = this;
        if( !rootPage ){
          this.page = adminsPage
        }else if (rootPage) {
          this.page = rootPage
        }
        this.getAdmins(this.page)
          .catch(err=>{
            console.info('getAdmins.err: ', err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      onChangePage(e){
        this.autoLoadHomeData(e.page);
      },
      onRefresh(){
        this.autoLoadHomeData(1);
      },
      getRegulatorTitle(regulator, admin){
        const { lang } = this;
        const { sub_account } = admin;
        if( regulator.hasOwnProperty(lang) ){
          return `${sub_account ? this.$t("PAGES.ADMIN.HOME.LABEL.regulator") : ''}` + regulator[lang].title
        }
        return this.$t('COMMON.NO_BODY');
      },
      getActuatorTitle(actuator){
        const { lang } = this;
        if( actuator.hasOwnProperty(lang) ){
          return this.$t("PAGES.ADMIN.HOME.LABEL.actuator") + actuator[lang].title
        }
        return this.$t('COMMON.NO_BODY');
      }
    }
  }
</script>

<style lang="scss" scoped></style>
