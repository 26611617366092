<template>
  <div class="navigation-form-container">
    <b-row>
      <b-col sm="6" md="2">
        <b-form-group id="input-group-is_show" :label="$t('COMMON.FORM.LABEL.is_show')" label-for="is_show">
          <b-form-radio-group
            id="is_show"
            v-model="formData.is_show"
            :options="yesOrNot"
            buttons />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="4" v-if="formData.view_type === 'banner'">
        <b-form-group id="input-belongs" :label="$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.belongs_t')" label-for="belongs">
          <b-form-checkbox-group size="lg" id="belongs" v-model="formData.belongs" :options="belongs" name="belongs" ></b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="3" >
        <b-form-group id="input-group-title" :label="`${titleForViewType} ${$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.title')} `" label-for="title" >
          <b-form-input id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" :placeholder="`${titleForViewType} ${$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.PLACEHOLDER.title')} `" />
          <b-form-invalid-feedback id="title-live-feedback">
            {{ titleForViewType }} {{ $t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.FEEDBACK.title') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="viewType === 'navigation'" sm="6" md="3">
        <b-form-group id="input-group-background" :label="$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.background')" label-for="title-color">
          <b-input-group>
            <b-form-input id="background" :placeholder="$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.PLACEHOLDER.background')" class="direction-ltr" v-model="formData.color" />
            <b-input-group-append>
              <b-button class="d-flex align-items-center justify-content-center">
                <colorPicker ref="colorPicker" v-model="formData.color || ''" @change="changeTitleColor" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-9" :label="$t('COMMON.FORM.LABEL.sort')" label-for="sort">
          <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" :placeholder="$t('COMMON.FORM.PLACEHOLDER.sort')" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="2" v-if="viewType === 'banner'">
        <b-form-group id="input-view_type" :label="`${titleForViewType} ${$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.view_type')} `" label-for="view_type">
          <b-form-radio-group id="view_type" v-model="formData.view_type" :options="viewTypes" buttons name="radios-btn-default" />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="6">
        <b-form-group :state="validateState('target')" aria-describedby="target-live-feedback" id="input-group-2" :label="`${titleForViewType} ${$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.target')} `" :description="optionDesc" label-for="target">
          <b-form-radio-group id="target" v-model="formData.target" @change="changeTarget" :options="targetOptions" buttons name="radios-btn-default" />
          <b-form-invalid-feedback id="target-live-feedback">
            {{ titleForViewType }} {{ $t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.FEEDBACK.target') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row >
      <template v-if="formData.target && formData.target !== 'contract'">
      <b-col sm="6" md="3">
        <b-form-group :label="targetLabel" >
          <b-input-group>
            <b-form-input :state="validateState('to')" aria-describedby="target-live-feedback" v-model="shouldChoose ? targetTitle : formData.to" :placeholder="feedBackLabel" :readonly="shouldChoose " />
            <b-input-group-append v-if="shouldChoose">
              <b-button class="d-flex justify-content-center align-content-center" @click="onClickArticleChoose" v-if="formData.target === 'article'" > {{ $t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.BUTTON.choose') }} </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback id="target-live-feedback">{{ feedBackLabel }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      </template>
      <b-col v-if="formData.target === 'mp'" sm="6" md="2">
        <b-form-group id="input-group-target-option" label="appid">
          <b-form-input class="direction-ltr" id="appid" v-model="formData.target_option" :state="validateState('target_option')" type="text" aria-describedby="target_option-live-feedback" :placeholder="$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.PLACEHOLDER.target_option_mp')" />
          <b-form-invalid-feedback id="target_option-live-feedback">
            {{ $t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.FEEDBACK.target_option_mp') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="3">
        <b-form-group :state="validateState('navigation_image')" aria-describedby="target-live-feedback" id="input-group-navigation_image" :description="navigationImageDesc" :label="uploaderLabel" label-for="navigation_image">
          <image-uploader v-model="formData.navigation_image" :upload-form="{strategy: viewType}" :accepted-file-types="acceptedFileTypes" />
          <b-form-invalid-feedback id="target-live-feedback">
            {{ uploaderLabel }} {{ $t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.UPLOADER_LABEL.label') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <choose-article-modal :title="$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.article_choose_modal_title')" v-model="articleModalState" @change="onChangeArticle" :formValue="formData.target === 'article' ? formData.target_option : 0"  image-field="cover_image_preview" :title-field="`raise.title.${lang}.title`" :describe-field="`raise.title.${lang}.describe`" />
  </div>
</template>

<script>
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import ChooseArticleModal from "@v@/components/modals/choose.article.modal";
  import navigationMixin from "@m@/navigation.mixin";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import "@c@/plugins/vue-color-picker";
  import {sprintf} from "@c@/helpers/helpers";
  import { mapGetters } from "vuex";
  export default {
    name: "NavigationForm",
    mixins: [ validationMixin, validateStateMixin, navigationMixin, sweetAlertMixin ],
    components: { ImageUploader, ChooseArticleModal },
    props: {
      action: {
        type: String,
        default: 'create'
      },
      modelId: {
        type: [String, Number],
      },
      viewType: {
        type: String,
        default: ()=> 'banner',
        validator: function (value) {
          // 这个值必须匹配下列字符串中的一个
          return ['banner', 'navigation'].includes(value)
        }
      }
    },
    validations(){
      let validates = {
        title: { required },
        navigation_image: { required },
        target: { required },
        to: { required: requiredIf((formData)=>{
          return formData.target !== 'contract';
        })},
        target_option: { required: requiredIf((formData)=>{
          return formData.target === 'mp';
        })},
      };
      if ( this.viewType === 'banner' ){}else if ( this.viewType === 'navigation' ){
        // validates['color'] = { required };
      }
      return {
        formData: validates
      }
    },
    computed: {
      ...mapGetters(['articles']),
      currentArticleTitle(){
        let { target, to } = this.formData;
        let { articles, lang } = this;
        if( target === 'article' && to ){
          const article = articles.map(item =>{
            if( item.id === to ){
              return item;
            }
            return null;
          }).filter(item => item);
          if( article.length ){
            const { raise: { title } } = article[0] || {};
            return title.hasOwnProperty(lang) ? title[lang].title : ''
          }
        }
        return null;
      },
      lang(){
        const { locale } = this.$i18n;
        return locale;
      },
      toLabel(){
        switch (this.formData.target) {
          case 'article':
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TO_LABEL.article');
          case 'mp':
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TO_LABEL.mp');
          case 'external_link':
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TO_LABEL.external_link');
        }
      },
      titleForViewType(){
        switch (this.viewType) {
          case "banner":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.VIEW_TYPE_TITLE.banner')
          case "navigation":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.VIEW_TYPE_TITLE.navigation')
          case "landscape":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.VIEW_TYPE_TITLE.landscape')
        }
      },
      optionDesc(){
        let str = this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.OPTION_DESC.str');
        switch (this.formData.target) {
          case "article":
            return sprintf(str, this.titleForViewType, this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.OPTION_DESC.article'))
          case "mp":
            return sprintf(str, this.titleForViewType, this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.OPTION_DESC.mp'))
          case "external_link":
            return sprintf(str, this.titleForViewType, this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.OPTION_DESC.external_link'))
        }
      },
      uploaderLabel(){
        switch (this.viewType) {
          case "banner":
          case "landscape":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.UPLOADER_LABEL.banner')
          case "navigation":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.UPLOADER_LABEL.navigation')
        }
      },
      acceptedFileTypes(){
        switch (this.viewType) {
          case "banner":
            return "image/png, image/gif, image/jpg, image/jpeg"
          case "navigation":
            return "image/png, image/gif"
        }
      },
      targetLabel(){
        switch (this.formData.target) {
          case "article":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TARGET_LABEL.article')
          case "mp":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TARGET_LABEL.mp')
          case "external_link":
            return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TARGET_LABEL.external_link')
        }
      },
      feedBackLabel(){
        let str = this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.FEEDBACK_LABEL.str_1');
        let str_1 = this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.FEEDBACK_LABEL.str_2');
        switch (this.formData.target) {
          case "article":
            return sprintf(str, this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.FEEDBACK_LABEL.article'))
          case "mp":
            return sprintf(str_1, this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.FEEDBACK_LABEL.mp'))
          case "external_link":
            return sprintf(str_1, this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.FEEDBACK_LABEL.external_link'))
        }
      },
      shouldChoose(){
        return ['article', 'page'].includes(this.formData.target)
      },
      pages(){
        let str = "رەسىمنى چەككەندە %s ئاتلايدۇ. %s ";
        return [
          {
            title: 'باشبەت',
            value: 'home',
            description: sprintf(str, 'باشبەتكە ' , '')
          },
          {
            title: 'ئەزالار مەركىزى',
            value: 'member',
            description: sprintf(str, 'ئەزالار مەركىزىگە ' , '')
          },
        ];
      },
      belongs(){
        let { belongs = ['home'] } = this.formData;
        return [
          {
            text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.BELONGS.any'),
            value: 'any',
            disabled: ! belongs.includes('any') && belongs.length
          },
          {
            text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.BELONGS.home'),
            value: 'home',
            disabled: belongs.includes('any')
          },
          {
            text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.BELONGS.show'),
            value: 'show',
            disabled: belongs.includes('any')
          },
          {
            text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.BELONGS.member'),
            value: 'member',
            disabled: belongs.includes('any')
          }
        ];
      },
      navigationImageDesc(){
        let { view_type } = this.formData;
        if( view_type === 'banner' ){
          return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.IMAGE_DESC.banner')
        }else if( view_type === 'landscape' ){
          return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.IMAGE_DESC.landscape')
        }else if( view_type === 'navigation' ){
          return this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.IMAGE_DESC.navigation')
        }
      },
      targetOptions(){
        return [
          { text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TARGET_OPTION.article'), value: 'article' },
          { text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TARGET_OPTION.mp'), value: 'mp' },
          { text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.TARGET_OPTION.external_link'), value: 'external_link' },
          // { text: 'ئىچكى بەتلەر', value: 'page' }
        ];
      }
    },
    created() {
      if (this.action === 'edit'){
        this.getNavigation(this.modelId)
          .then(({ data: navigation })=>{
            this.formData = navigation;
            if ( navigation.navigationable ){
              if( navigation.target === 'article' ){
                const { raise: { title } } = navigation.navigationable || {};
                const { lang } = this;
                const titles = title.map(item =>{
                  if( item.language === lang ){
                    return item;
                  }
                  return false
                }).filter( item => item);
                if( titles.length ){
                  this.updateTargetTitle(titles[0].title);
                }
              }else {
                this.updateTargetTitle(navigation.navigationable.hasOwnProperty('title') ? navigation.navigationable.title : '');
              }
            }
            if( navigation.belongs.includes('show') ){
              this.belongsTitle = navigation.belongsable && navigation.belongsable.title
            }
            if( ['navigation', 'landscape'].includes(navigation.view_type) ){
              this.formData.belongs = ['home'];
            }
          })
      }
    },
    mounted() {
      this.initViewType();
    },
    watch: {
      'formData.view_type': {
        handler(v){
          if ( v !== 'banner' && !! v ){
            this.formData.belongs = ['home'];
          }
        },
        immediate: true
      }
    },
    data(){
      return {
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        formData: {
          view_type: "",
          title: '',
          color: null,
          background: null,
          target: '',
          to: '',
          target_option: null,
          sort: '',
          icon: null,
          is_show: true,
          belongs: ['home'],
          belongs_option: ''
        },
        viewTypes: [
          { text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.VIEW_TYPE.BANNER'), value: 'banner' },
          { text: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.LABEL.VIEW_TYPE.LANDSCAPE'), value: 'landscape' },
        ],
        targetTitle: null,
        belongsTitle: null,
        articleModalState: false
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.TIPS.VALIDATION_TIPS')});
        }
        if (this.action === 'create'){
          this.storeNavigation(this.formData);
        }else if ( this.action === 'edit' ){
          this.updateNavigation(this.formData);
        }
      },
      changeTitleColor(e){
        this.formData.color = e;
      },
      changeTarget(e){
        this.formData.to = null;
        this.targetTitle = null;
      },
      initViewType(){
        if ( this.viewType === 'navigation' ){
          this.formData.view_type = 'navigation';
        }else {
          this.formData.view_type = 'banner';
        }
      },

      updateSomeFormData(key = 'to', val = null){
        this.formData[key] = val;
      },
      updateTargetTitle(targetTitle = null){
        this.targetTitle = targetTitle;
      },
      onClickArticleChoose(e){
        this.articleModalState = true;
      },
      onChangeArticle(e){
        this.formData.to = e;
        this.$nextTick(()=>{
          this.articleModalState = false;
          this.targetTitle = this.currentArticleTitle;
        });
      },
    }
  }
</script>
