<template>
  <b-overlay :show="loading">
    <admin-form :id="id" action="edit" @loading="onLoading" @loaded="onLoaded" />
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Portlet from "@v@/partials/content/Portlet";
  import Error from "@v@/partials/widgets/Error";
  import AdminForm from "@v@/components/forms/AdminForm"
  export default {
    name: "index",
    components: {Portlet, Error, AdminForm},
    mixins: [],
    props: {
      id: {
        type: [Number, String]
      }
    },
    mounted() {
    },
    created() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADMIN.EDIT.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'admin.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADMIN.EDIT.BREAD_CRUMBS_TITLES.CURRENT_TITLE')});
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      onLoading(){
        this.loading = true;
      },
      onLoaded(e){
        this.loading = false;
      },
    }
  }
</script>

<style scoped>
.footer-actions {
  gap: 10px;
}
</style>
